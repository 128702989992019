import request from '@/../utils/request'
const api_name = '/iotechserver/notifyCompanyUser'

export default {
    // 通过公司id返回人员列表 *
    getNotifyCompanyUser(ids) {
        return request({
            url: `${api_name}/getNotifyCompanyUser/${ids}`,
            method: 'get',
        })
    },

    // 删除用户 *
    deleteNotifyCompanyUser(phone, ids) {
        return request({
            url: `${api_name}/deleteNotifyCompanyUser/${phone}/${ids}`,
            method: 'delete'
        })
    },

    // 添加用户 *
    addNotifyCompanyUser(NotifyCompanyUserVo) {
        return request({
            url: `${api_name}/addNotifyCompanyUser`,
            method: 'post',
            data: NotifyCompanyUserVo
        })
    },



    // -----增加——>util_inclusion_relation-----  *
    addNotifyUtilInclusionRelation(NotifyUtilInclusionRelation) {
        return request({
            url: `${api_name}/addNotifyUtilInclusionRelation`,
            method: 'post',
            data: {
                "ids": NotifyUtilInclusionRelation["ids"],
                "phone": NotifyUtilInclusionRelation["phone"],
                "notifyBigSmallSystemInfo": NotifyUtilInclusionRelation["bigSmallSystemInfo"]
            }
        })
    },

    //  查看所有权限
    getAllCommonNotify(ids, phone) {
        return request({
            url: `${api_name}/getAllCommonNotify/${ids}/${phone}`,
            method: 'get',
        })
    }
}