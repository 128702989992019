<template>
  <div id="app" class="url">
    <!-- <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="通知类型分配"> </el-page-header>
      </el-col>
    </el-row> -->

    <el-row style="margin: 10px 0">
      <el-button type="success" icon="el-icon-check" @click="dialogVisible = true">选择公司</el-button>
      <el-button :disabled="usable" type="success" icon="el-icon-plus" @click="dialogVisible1 = true">添加用户</el-button>
    </el-row>

    <!-- 公司信息列表 -->
    <el-dialog title="公司信息" :visible.sync="dialogVisible" width="40%" append-to-body :close-on-click-modal="false">
      <!-- default-expand-all -->
      <div style="height: 260px; overflow-y: scroll">
        <el-tree :check-strictly="true" :data="allCompanyList" show-checkbox node-key="id" ref="tree" highlight-current :props="defaultProps" accordion>
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedKeys">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 添加人员选择 弹框 -->
    <el-dialog title="添加用户" :visible.sync="dialogVisible1" width="30%" :close-on-click-modal="false">
      <div>
        <table>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 10px 0">姓名：</el-tag>
            </td>
            <td style="width: 100%">
              <el-input v-model="personnel.name" placeholder="请输入姓名"></el-input>
            </td>
          </tr>
          <tr>
            <td>
              <el-tag style="font-size: 16px; margin: 0 10px 0 0">电话：</el-tag>
            </td>
            <td>
              <el-input v-model="personnel.phone" placeholder="请输入电话" type="number" style="width: 100%"></el-input>
            </td>
          </tr>
        </table>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="addPersonnel">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 给添加的人  添加权限  弹框-->
    <el-dialog title="编辑通知方式" :visible.sync="dialogVisible2" :close-on-click-modal="false" width="50%">

      <!-- accordion -->
      <div style="height: 200px; overflow-y: scroll">
        <el-tree :check-strictly="false" :data="personnelNotifyList" show-checkbox node-key="uuid" ref="tree1" highlight-current :props="defaultProps1" accordion>
        </el-tree>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="getCheckedNodes">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 公司对应的 人员信息列表 -->
    <el-table border :data="personnelInfoList" style="width: 100%" height="700">
      <el-table-column align="center" label="序号" type="index" width="80">
      </el-table-column>
      <el-table-column align="center" prop="name" label="姓名">
      </el-table-column>
      <el-table-column align="center" prop="phone" label="电话">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="编辑通知类型" width="180">
        <!-- 添加权限 -->
        <template slot-scope="scope">
          <el-button type="primary" plain size="mini" @click="editPersonnel(scope.row)">编辑</el-button>
          <el-button type="danger" plain size="mini" @click="delPersonnel(scope.row)">删除</el-button>
        </template>

      </el-table-column>
    </el-table>
  </div>
</template>

<script>
// 公司系统
import companySystem from "@/api/managementApi/CompanyInfo";

// import notifymethodsystem from '@/api/managementApi/NotifyMethodSys'

// 大小系统关系
import notifyBigSmallSys from '@/api/managementApi/NotifyBigSmallSys'

// 大小系统
import jurisBigSmallSys from "@/api/managementApi/JurisBigSmallSys";
// 公司权限分配操作
import notifyCompanyUser from "@/api/managementApi/NotifyCompanyUser"

export default {
  data() {
    return {
      defaultChecked: [],
      bigSmallSystemInfo: [],
      a: {},
      b: {},
      addNotifyUtilInclusionRelation: {},
      ids: [],
      phone: "",
      editPhone: "",

      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      usable: true,
      noticeTypeList: [], // 通知类型列表

      allCompanyList: [],    // 公司树

      personnelNotifyList: [], //编辑用户通知权限树
      checkedKeys: "", // 通过key获取被选择的公司id
      checkedNodes: "", // 通过node获取被选权限id

      personnel: {   // 添加人员绑定
        name: "",
        phone: null,
      },

      defaultProps: {   // 公司树
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },

      defaultProps1: {    // 用户权限树
        children: "jurisSmallSystems",
        label: "smallSystemName",
      },


      // 公司对应人员列表
      personnelInfoList: [],

    };
  },
  created() {
    this.getNotifySys(0)
    this.getAllCompanyList(); // 查询到所有公司列表
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    editPersonnel(row) {
      this.defaultChecked = []
      this.editPhone = row.phone
      this.dialogVisible2 = true
      this.getAllCommonNotifyList()
    },

    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list;
      });
    },

    // 选择公司  确定按钮
    getCheckedKeys() {
      this.checkedKeys = this.$refs.tree.getCheckedKeys();

      if (this.$refs.tree.getCheckedKeys() == "") {
        alert("请选择公司");
      } else {
        this.dialogVisible = false;
        this.getCompanyPersonnelInfo(this.checkedKeys);
        this.usable = false; // 添加人员 按钮可用
      }
    },

    // 通过公司id获取人员列表信息
    getCompanyPersonnelInfo(checkedKeys) {
      notifyCompanyUser.getNotifyCompanyUser(checkedKeys).then((res) => {
        this.personnelInfoList = res.data.data.list;
      });
    },

    // 编辑通知权限 树  查所有
    getNotifySys() {
      notifyBigSmallSys.getNotifyBigSmallSystemInfo(0).then((res) => {
        this.personnelNotifyList = res.data.data.systemInfo;
        for (let i = 0; i < this.personnelNotifyList.length; i++) {
          this.personnelNotifyList[i]["smallSystemName"] =
            this.personnelNotifyList[i]["bigSystemName"];
          for (let j = 0; j < this.personnelNotifyList[i].jurisSmallSystems.length; j++) {
            this.personnelNotifyList[i].jurisSmallSystems[j]['jurisSmallSystems'] = [{
              id: 'sendWeChat',
              smallSystemName: '微信',
              status: 0,
            }, {
              id: 'sendPhone',
              smallSystemName: '电话',
              status: 0,
            }, {
              id: 'sendMessages',
              smallSystemName: '短信',
              status: 0,
            }]

          }
        }
        // ----------
        for (let i in this.personnelNotifyList) {
          this.personnelNotifyList[i]["uuid"] = this.personnelNotifyList[i].bigSystemId
          for (let j in this.personnelNotifyList[i].jurisSmallSystems) {
            this.personnelNotifyList[i].jurisSmallSystems[j]["uuid"] = "" + this.personnelNotifyList[i].bigSystemId + "_" + this.personnelNotifyList[i].jurisSmallSystems[j].id
            this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][0]["uuid"] = this.personnelNotifyList[i].jurisSmallSystems[j]["uuid"] + "_" + this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][0]["id"]
            this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][1]["uuid"] = this.personnelNotifyList[i].jurisSmallSystems[j]["uuid"] + "_" + this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][1]["id"]
            this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][2]["uuid"] = this.personnelNotifyList[i].jurisSmallSystems[j]["uuid"] + "_" + this.personnelNotifyList[i].jurisSmallSystems[j]["jurisSmallSystems"][2]["id"]
          }
        }
        // ----------

      })
    },

    // 修改通知方式的状态值
    changeNotifyType() {
      for (let i = 0; i < this.checkedNodes.length; i++) {
        this.checkedNodes[i].status = 1;
      }
    },


    // 传编辑通知方式选中的对象id

    getCheckedNodes() {
      this.bigSmallSystemInfo = []
      this.a = {}
      this.b = {}

      this.ids = this.checkedKeys
      this.phone = this.editPhone

      for (let i in this.$refs.tree1.getCheckedNodes()) {
        if (this.$refs.tree1.getCheckedNodes()[i]["uuid"].indexOf("send") != -1) {
          let bigId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[0]
          let smallId = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[1]
          let send = this.$refs.tree1.getCheckedNodes()[i]["uuid"].split("_")[2]
          if (this.a[bigId] == null) {
            this.a[bigId] = this.bigSmallSystemInfo.length
            this.bigSmallSystemInfo.push({
              bigSystemId: bigId,
              notifySmallSystemsMethod: [
              ]
            })
          }

          if (this.b[bigId + "_" + smallId] == null) {
            this.b[bigId + "_" + smallId] = this.bigSmallSystemInfo[this.a[bigId]].notifySmallSystemsMethod.length
            this.bigSmallSystemInfo[this.a[bigId]].notifySmallSystemsMethod.push(
              { id: smallId, sendWeChat: 0, sendPhone: 0, sendMessages: 0 }
            )
          }

          this.bigSmallSystemInfo[this.a[bigId]].notifySmallSystemsMethod[this.b[bigId + "_" + smallId]][send] = 1

        }
      }
      this.addNotifyUtilInclusionRelation = { ids: this.ids, phone: this.phone, bigSmallSystemInfo: this.bigSmallSystemInfo }

      // 调用函数
      this.addNotifyRelation();
    },

    // -----增加——>util_inclusion_relation-----
    addNotifyRelation() {
      notifyCompanyUser.addNotifyUtilInclusionRelation(this.addNotifyUtilInclusionRelation).then(() => {
        // this.$message.success("添加成功！")

        this.dialogVisible2 = false;
      })
    },








    // 添加人员  确定按钮
    addPersonnel() {
      if (this.checkedKeys == '') {
        alert('请选择公司')
      } else
        if (this.personnel.name == "" || this.personnel.phone == "" || this.personnel.phone == null) {

          this.$message.error("姓名和电话不能为空");
        } else {
          notifyCompanyUser.addNotifyCompanyUser({
            phone: this.personnel.phone,
            name: this.personnel.name,
            ids: this.checkedKeys

          }).then(() => {
            this.dialogVisible1 = false;
            this.personnel.name = "";
            this.personnel.phone = "";
            // this.getAllCompanyList();
            // 重新加载人员列表
            this.getCompanyPersonnelInfo(this.checkedKeys);
            this.personnel.name = '';
            this.personnel.phone = null

          });
        }
    },


    // 选项
    // handleCheckedNoticeChange(value) {
    //   let checkedCount = value.length;
    //   this.checkAll = checkedCount === this.notices.length;
    //   this.isIndeterminate = checkedCount > 0 && checkedCount < this.notices.length;
    // },

    // 删除人员         （根据公司查到的人员列表）
    delPersonnel(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        notifyCompanyUser.deleteNotifyCompanyUser(row.phone, this.checkedKeys).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //重新加载人员列表
          this.getCompanyPersonnelInfo(this.checkedKeys);

        });
      });
    },

    // 查看所有权限
    getAllCommonNotifyList() {
      this.defaultChecked = []
      notifyCompanyUser.getAllCommonNotify(this.checkedKeys.toString(), this.editPhone).then((res) => {
        let defaultCheckedls = []

        for (let i in res.data.data.list) {
          for (let j in res.data.data.list[i].notifySmallSystemsMethod) {
            for (let k in res.data.data.list[i].notifySmallSystemsMethod[j]) {

              if (res.data.data.list[i].notifySmallSystemsMethod[j][k] == 1) {
                defaultCheckedls.push("" + res.data.data.list[i].bigSystemId + "_" + res.data.data.list[i].notifySmallSystemsMethod[j].id + "_" + k)
              }
            }
          }
        }
        this.defaultChecked = defaultCheckedls
        this.$refs.tree1.setCheckedKeys(this.defaultChecked)

      })
    }
  },
};
</script>



<style lang="scss" scoped>
#app{
  box-sizing: border-box;
  padding: 0 5px;
}
.add-item {
  margin-top: 10px;
}
.checkBtn {
  margin-left: 60px;
}
.select-item {
  width: 85%;
}
</style>