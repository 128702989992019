import request from '@/../utils/request'
const api_name = '/iotechserver/notifybigsmallsystem'


export default {

    // 获取大小系统关系      获取全表 
    getNotifyBigSmallSystem() {
        return request({
            url: `${api_name}/getNotifyBigSmallSystem`,
            method: 'get',
        })
    },

    // 获取通知系统名称
    getNotifyBigSmallSystemInfo(id) {
        return request({
            url: `${api_name}/getNotifyBigSmallSystemInfo/${id}`,
            method: 'get',
        })
    },

    // 根据大系统，重新编辑小系统  

    updateNotifyBigSmallSystem(bigSystemId, smallSystemIds) {
        return request({
            url: `${api_name}/updateNotifyBigSmallSystem`,
            method: 'post',
            data: {
                bigSystemId: bigSystemId,
                smallSystemIds: smallSystemIds
            }
        })
    },

    // 删除大小系统关系 
    deleteNotifyBigSmallSystem(bigSystemId, smallSystemId) {
        return requset({
            url: `${api_name}/deleteNotifyBigSmallSystem/${bigSystemId}/${smallSystemId}`,
            method: 'delete',
        })
    },


    // 删除大小系统关系
    deleteJurisBigSmallSystem(bigSystemId, smallSystemId) {
        return request({
            url: `${api_name}/deleteJurisBigSmallSystem/${bigSystemId}/${smallSystemId}`,
            method: 'delete',
        })
    },


    // 增加  
    addNotifyBigSmallSystem() {
        return request({
            url: `${api_name}/addNotifyBigSmallSystem`,
            method: 'post'
        })
    },


    // 根据大系统查询小系统
    getNotifyBigSmallSystemInfo(bigSystemId) {
        return request({
            url: `${api_name}/getNotifyBigSmallSystemInfo/${bigSystemId}`,
            method: 'get'
        })
    }

}